.card-item {
  border: 5px solid rgb(255, 255, 255);
  opacity: 0.8;
  max-width: 250px;
  transition: opacity 100ms ease-in;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background: white;
  &:hover {
    opacity: 1;
    text-decoration: none;
  }
  cursor: pointer;

  .date {
    font-size: 0.6em;
    color: rgb(126, 126, 126);
  }
  .caption {
    text-align: center;
    font-family: $handwritting-font-family;
    margin-top: 0.5rem;
    font-size: 1.5em;
    color: rgb(58, 58, 58);
  }
  .image {
    height: 100px;
    overflow: hidden;
  }
}

// @media only screen and (max-width: 575px) {
//   .card-item {
//     width: 350px;
//     height: 260px;

//     .image {
//       height: 200px;
//       overflow: hidden;
//     }
//   }

//   .about-text {
//     padding-left: 16px;
//   }
// }
