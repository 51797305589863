.Site {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.Site-content {
  flex: 1;
}

.footer {
  bottom: 0px;
  height: 50px;
}
