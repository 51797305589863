@import './base/base';
@import './layouts/sticky-footer';

//components
@import './components/imageViewBox';

.footer {
  background: rgb(138, 138, 138);
}

.header {
  height: 75px;
  background: rgb(80, 80, 80);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 1rem;
  color: white;
  background-image: url('../images/background1.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;

  .media {
    justify-content: space-between;
    align-items: center;
  }
}

.img-fluid {
  max-width: 100%;
  height: auto;
}
